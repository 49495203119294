import React from "react"
import { Grid, styled, Typography } from '@mui/material'
import { LoadingContainer } from "./LoadingContainer"
import { PoweredBy } from "./PoweredBy"
import { ReactComponent as FaceSvg } from "./face.svg"

const Container = styled(LoadingContainer)(({ theme }) => ({
    color: 'white',
    padding: '4rem',
    '& h6, h5': {
        fontFamily: 'Montserrat',
        fontWeight: '500'
    },
    '& svg': {
        width: '4rem',
        transform: 'rotate(180deg)',
    }
}))

function Permissions() {
    return (
        <Container>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ height: '100%' }}>
                <Grid item xs={12}>
                    <Typography component="h1" textAlign="center" paragraph>
                        <FaceSvg />
                    </Typography>
                    <Typography variant="h5" component="h5" textAlign="center" paragraph>
                        Nothing to see here!
                    </Typography>
                    <Typography variant="body1" component="h6" textAlign="center">
                        You'll need to refresh and tap 'Allow' if you want to access the AR experience.
                    </Typography>
                </Grid>
            </Grid>
            <PoweredBy />
        </Container>
    )
}

export { Permissions }