import { createTheme, ThemeProvider } from '@mui/material'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import Viewer from './components/Viewer'

const theme = createTheme()

function App() {
    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <Switch>
                    <Route path="/:stage/:projectId" component={Viewer} />
                    <Route path="/:projectId" component={Viewer} />
                    <Redirect from="/*" to="/none" />
                </Switch>
            </ThemeProvider>
        </BrowserRouter>
    )
}

export default App
