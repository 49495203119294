import React, { useCallback, useEffect, useState } from 'react'
import { styled, useMediaQuery } from '@mui/material'
import { keyframes } from '@mui/system'
import { WindowContainer } from './WindowContainer'

const Container = styled(WindowContainer)(({ theme }) => ({
    zIndex: 3,
}))

const Bar = styled('div')(({ theme }) => ({
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    display: 'flex',
    zIndex: 3,
}))

const Corner = styled(Bar)(({ theme }) => ({
    backgroundColor: 'white',
    zIndex: 4,
}))

const StartButton = styled('button')(({ theme }) => ({
    position: 'absolute',
    bottom: '20px',
    left: 'calc(50vw - 3rem)',
    width: '6rem',
    height: '6rem',
    background: 'transparent',
    border: 'none',
    zIndex: 5,
    '&:hover': {
        cursor: 'pointer',
    },
    '> img': {
        width: '100%',
    },
    '&:active > img': {
        opacity: 0.8,
    },
    '&:disabled > img': {
        opacity: 0.2,
    },
}))

const Scanner = styled('div')(({ theme }) => ({
    margin: 'auto',
    height: '4px',
    backgroundColor: '#66ff00',
    opacity: 0.8,
    position: 'relative',
    zIndex: 3,
}))

const landscape = {
    w: 7,
    h: 5,
}

const portrait = {
    w: 3,
    h: 4,
}

function Scanning({
    orientation = 'LANDSCAPE',
    ready,
    hidden,
    button,
    showScannerEffect = false,
    scanning,
    onScanning,
}) {
    const isSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))
    const cornerWidth = 6
    const cornerLenght = 40

    const [loaded, setLoaded] = useState(false)
    const [top, setTop] = useState(0)
    const [bottom, setBottom] = useState(0)
    const [width, setWidth] = useState(0)
    const [scannerHeight, setScannerHeight] = useState(0)
    const [scannerWidth, setScannerWidth] = useState(0)
    const [kframe, setKframe] = useState()

    const updateViewport = useCallback(
        (event) => {
            const maxWidth = isSm ? 380 : 480
            const top = orientation === 'LANDSCAPE' ? 100 : 40
            const margin = orientation === 'LANDSCAPE' ? 60 : 80

            let w, h
            if (maxWidth + margin < window.innerWidth) {
                w = maxWidth
            } else {
                w = window.innerWidth - margin
            }

            if (orientation === 'LANDSCAPE') {
                h = Math.round((w * landscape.h) / landscape.w)
            } else {
                h = Math.round((w * portrait.h) / portrait.w)
            }

            const width = (window.innerWidth - w) / 2
            const bottom = top + h

            const kframe = keyframes`
            0% {
                top: ${top}px;
                opacity: 1;
            }
            50% {
                top: ${bottom - 4}px;
                opacity: 1;
            }
            90% {
                opacity: 1;
            }
            100% {
                top: ${top}px;
                opacity: 0;
            }
        `
            setTop(top)
            setBottom(bottom)
            setWidth(width)
            setScannerHeight(h)
            setScannerWidth(w)
            setKframe(kframe)
            setLoaded(true)
        },
        [orientation, isSm]
    )

    useEffect(() => {
        window.addEventListener('resize', updateViewport)

        updateViewport()

        return () => {
            window.removeEventListener('resize', updateViewport)
        }
    }, [updateViewport])

    if (!loaded) {
        return <></>
    }

    return (
        <Container
            id="scanning-overlay"
            sx={hidden ? { visibility: 'hidden' } : undefined}
        >
            {/* top */}
            <Bar sx={{ top: 0, right: 0, left: 0, height: `${top}px` }} />
            {/* right */}
            <Bar
                sx={{
                    top: top,
                    right: 0,
                    height: `${scannerHeight}px`,
                    width: `${width}px`,
                }}
            />
            {/* left */}
            <Bar
                sx={{
                    top: top,
                    left: 0,
                    height: `${scannerHeight}px`,
                    width: `${width}px`,
                }}
            />
            {/* bottom */}
            <Bar sx={{ top: bottom, right: 0, bottom: 0, left: 0 }} />

            {/* top-left */}
            <Corner
                sx={{
                    top: top,
                    left: width - cornerWidth / 2,
                    width: `${cornerWidth}px`,
                    height: `${cornerLenght}px`,
                }}
            />
            <Corner
                sx={{
                    top: top - cornerWidth / 2,
                    left: width - cornerWidth / 2,
                    width: `${cornerLenght}px`,
                    height: `${cornerWidth}px`,
                }}
            />

            {/* top-right */}
            <Corner
                sx={{
                    top: top,
                    right: width - cornerWidth / 2,
                    width: `${cornerWidth}px`,
                    height: `${cornerLenght}px`,
                }}
            />
            <Corner
                sx={{
                    top: top - cornerWidth / 2,
                    right: width - cornerWidth / 2,
                    width: `${cornerLenght}px`,
                    height: `${cornerWidth}px`,
                }}
            />

            {/* bottom-left */}
            <Corner
                sx={{
                    top: bottom - cornerLenght,
                    left: width - cornerWidth / 2,
                    width: `${cornerWidth}px`,
                    height: `${cornerLenght}px`,
                }}
            />
            <Corner
                sx={{
                    top: bottom - cornerWidth / 2,
                    left: width - cornerWidth / 2,
                    width: `${cornerLenght}px`,
                    height: `${cornerWidth}px`,
                }}
            />

            {/* bottom-right */}
            <Corner
                sx={{
                    top: bottom - cornerLenght,
                    right: width - cornerWidth / 2,
                    width: `${cornerWidth}px`,
                    height: `${cornerLenght}px`,
                }}
            />
            <Corner
                sx={{
                    top: bottom - cornerWidth / 2,
                    right: width - cornerWidth / 2,
                    width: `${cornerLenght}px`,
                    height: `${cornerWidth}px`,
                }}
            />
            {showScannerEffect && scanning && (
                <Scanner
                    sx={{
                        width: `${scannerWidth}px`,
                        animation: `${kframe} 2s linear forwards`,
                    }}
                />
            )}
            {!button && !scanning && (
                <div style={{
                    position: 'absolute',
                    top: `${top}px`,
                    right: `${width - cornerWidth / 2}px`,
                    left: `${width - cornerWidth / 2}px`,
                    height: `${scannerHeight}px`,
                    backgroundImage: "url('/AR-Scan-Screen-Graphic.png')",
                    backgroundPosition: "center center",
                    backgroundSize: "70% auto",
                    backgroundRepeat: "no-repeat",
                }} />
            )}

            <StartButton onClick={onScanning} disabled={!ready || scanning}>
                <img src="/Start-Scan-Button.png" alt="start" />
            </StartButton>
        </Container>
    )
}

export { Scanning }
