const API_URL = process.env.REACT_APP_API_URL

const requestOptions = {
    method: 'GET',
    headers: {
        Accept: 'application/json',
    },
    mode: 'cors',
}

export const getProject = async (projectId, stage) => {
    const url = `${API_URL}/projects/${projectId}/preview?stage=${stage}`
    const response = await fetch(url, requestOptions)

    if (response.ok) {
        return await response.json()
    }

    const errorResponse = await response.json()
    if (errorResponse) {
        throw new Error(errorResponse.description)
    }
    throw new Error(await response.text())
}
