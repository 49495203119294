import React from 'react'
import { styled } from '@mui/material'

const Image = styled('img')(({ theme }) => ({
    position: 'absolute',
    bottom: '20px',
    width: '160px',
    left: 'calc((100% - 160px) / 2)',
    [theme.breakpoints.up('md')]: {
        bottom: '40px',
        width: '200px',
        left: 'calc((100% - 200px) / 2)',
    },
}))

function PoweredBy() {
    return (
        <Image
            src="/Powered-by-PixlBank-Logo.png"
            alt="powered by"
        />
    )
}

export { PoweredBy }