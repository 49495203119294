import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { styled } from '@mui/material'
import { WindowContainer } from './WindowContainer'
import clsx from 'clsx'

const Container = styled(WindowContainer)(({ theme }) => ({
    zIndex: 4,
}))

const OverlayButton = styled('a')(({ theme }) => ({
    position: 'absolute',
    width: '60vw',
    left: '20vw',
    height: 'calc(60vw * 0.22)',
    color: 'white',
    bottom: 'calc(6rem + 30px)',
    textAlign: 'center',
    textDecoration: 'none',
    visibility: 'hidden',
    zIndex: 5,
    [theme.breakpoints.up('md')]: {
        width: '240px',
        left: 'calc((100vw - 240px) / 2)',
        height: 'calc(240px * 0.22)',
    },
    '&.ButtonOut': {
        opacity: 0,
        visibility: 'hidden',
    },
    '&.ButtonIn': {
        visibility: 'visible',
        transition: 'all 0.5s',
    },
    '& > img': {
        width: '100%',
    },
}))

function Overlay({ hidden, button, video }) {
    const second = useMemo(() => {
        if (!button || !button.timing) return 0

        const segments = button.timing.split(':')
        const min = parseInt(segments[0])
        const sec = parseInt(segments[1])

        if (isNaN(min) || isNaN(sec)) return 0

        return min * 60 + sec
    }, [button])

    const [visible, setVisible] = useState(!hidden && second === 0)
    const [displayed, setDisplayed] = useState(false)

    const listenPlayback = useCallback(() => {
        if (!displayed) {
            const current = video?.currentTime ?? 0
            const visible = current >= second
            setVisible(visible)
            setDisplayed(visible)
        }
    }, [video, second, displayed])

    useEffect(() => {
        if (video && second > 0) {
            video.addEventListener('timeupdate', listenPlayback)

            return () => {
                video.removeEventListener('timeupdate', listenPlayback)
            }
        }
    }, [video, second, listenPlayback])

    return (
        <Container sx={hidden ? { visibility: 'hidden' } : undefined}>
            {button && (
                <OverlayButton
                    href={button.url}
                    title={button.text}
                    className={clsx({
                        ButtonOut: !visible,
                        ButtonIn: !!visible,
                    })}
                >
                    <img
                        src={button.image ?? '/default-button.png'}
                        alt={button.text}
                    />
                </OverlayButton>
            )}
        </Container>
    )
}

export { Overlay }
