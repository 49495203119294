import { styled } from '@mui/material'
import { WindowContainer } from './WindowContainer'

export const LoadingContainer = styled(WindowContainer)(({ theme }) => ({
    backgroundColor: 'rgba(29, 32, 89, 1)',
    backgroundImage: 'url(/Gradient-Background.jpg)',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    '&.hidden': {
        display: 'none',
    },
}))
