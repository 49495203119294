import React, { useEffect, useRef, useState } from 'react'
import { styled } from '@mui/material'

const Button = styled('button')(({ theme }) => ({
    width: '3rem',
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
    backgroundColor: 'rgba(0,0,0,0.5)',
    border: 'none',
    borderRadius: '5px 5px 5px 5px',
    zIndex: 5,
}))

const Image = styled('img')(({ theme }) => ({
    width: '100%',
}))

function SoundButton({ video }) {
    const buttonRef = useRef(null)
    const [muted, setMuted] = useState(true)

    useEffect(() => {
        if (video) {
            setMuted(video.muted)
        }
    }, [video])

    const toggleSound = () => {
        setMuted(!muted)
        for (let video of document.getElementsByTagName("video")) {
            video.muted = !muted;
            console.log("video", video.id, video.muted);
        }
    }

    if (!video) {
        return <></>
    }

    return (
        <Button ref={buttonRef} onClick={toggleSound}>
            {muted && <Image src="/Audio-Off-Icon.png" alt="muted" />}
            {!muted && <Image src="/Audio-On-Icon.png" alt="unmuted" />}
        </Button>
    )
}

export { SoundButton }
