import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Splash } from '../components/Splash'
import { SoundButton } from '../components/SoundButton'
import { Scanning } from '../components/Scanning'
import { Overlay } from '../components/Overlay'

function AFrameViewer({ project, onMissingPermissions }) {
    const sceneRef = useRef(null)
    const [ready, setReady] = useState('no') // no | permissions | yes
    const [found, setFound] = useState(false)
    const [scanning, setScanning] = useState(false)
    const [video, setVideo] = useState()
    const [arSystem, setArSystem] = useState()
    const [canvas, setCanvas] = useState()
    const [src, setSrc] = useState()
    const [index, setIndex] = useState()
    const [button, setButton] = useState()

    const playVideo = useCallback(
        (src, index) => {
            const video = document.querySelector(src)
            video.play()

            if (index === undefined) {
                setButton(undefined)
            } else {
                const button = project.image_targets[+index]?.button
                setButton(button)
            }

            setVideo(video)
            setFound(true)
            canvas?.style.setProperty('display', 'block')
        },
        [canvas, project]
    )

    const stopVideo = useCallback(
        (src) => {
            canvas?.style.setProperty('display', 'none')
            const video = document.querySelector(src)
            video.pause()

            setButton(undefined)
            setVideo(undefined)
            setFound(false)
        },
        [canvas]
    )

    const targetFound = useCallback(
        (event) => {
            const aVideo = event.currentTarget.firstElementChild
            const src = aVideo.getAttribute('src')
            const index = aVideo.getAttribute('data-index')

            playVideo(src, index)
            console.log('found and playing', src)
        },
        [playVideo]
    )

    const targetLost = useCallback(
        (event) => {
            const src =
                event.currentTarget.firstElementChild.getAttribute('src')
            stopVideo(src)

            console.log('lost and stopping', src)
        },
        [stopVideo]
    )

    const sourceFound = useCallback((event) => {
        const aVideo = event.currentTarget.firstElementChild
        const src = aVideo.getAttribute('src')
        const index = aVideo.getAttribute('data-index')
        setSrc(src)
        setIndex(index)
        console.log('found', src)
    }, [])

    const sourceLost = useCallback((event) => {
        const src = event.currentTarget.firstElementChild.getAttribute('src')
        setSrc(undefined)
        setIndex(undefined)
        console.log('lost', src)
    }, [])

    const getEntities = useCallback(() => {
        const entities =
            Array.from(document.querySelectorAll('a-entity')).filter((el) => {
                return el.hasAttribute('mindar-image-target')
            }) ?? []
        console.log(`${entities.length} entities were found`)
        return entities
    }, [])

    const loaded = useCallback(() => {
        const scene = sceneRef.current
        const mindarImageSystem = scene.systems['mindar-image-system']
        setArSystem(mindarImageSystem)

        scene?.addEventListener('arPermissions', (event) => {
            setReady('permissions')
        })

        scene?.addEventListener('arError', onMissingPermissions)

        scene?.addEventListener('arReady', (event) => {
            setReady('yes')
            mindarImageSystem.pause(true)

            const canvas = document.getElementsByClassName('a-canvas')[0]
            canvas?.style.setProperty('display', 'none')

            getEntities().forEach((target) => {
                target.addEventListener('targetFound', sourceFound)
                target.addEventListener('targetLost', sourceLost)
                console.log('added src listeners')
            })

            setCanvas(canvas)
        })
    }, [getEntities, sourceFound, sourceLost, onMissingPermissions])

    useEffect(loaded, [loaded])

    const startScanning = () => {
        console.log('enabling scanning')
        setScanning(true)

        for (let video of document.getElementsByTagName("video")) {
            video.muted = false;
            console.log("video", video.id, video.muted);
        }

        if (arSystem) {
            arSystem.unpause()

            const entities = getEntities()
            entities.forEach((target) => {
                target.removeEventListener('targetFound', sourceFound)
                target.removeEventListener('targetLost', sourceLost)
            })
            console.log('removed src listeners')

            entities.forEach((target) => {
                target.addEventListener('targetFound', targetFound)
                target.addEventListener('targetLost', targetLost)
            })
            console.log('added playing listeners')

            if (src) {
                console.log(`target previouly detected`)
                playVideo(src, index)
            }
        } else {
            console.log('ar is not ready yet')
        }
    }

    return (
        <>
            <a-scene
                ref={sceneRef}
                mindar-image={`imageTargetSrc: ${project.mind}; filter: SmoothDamp; filterSmoothTime: 0.05; uiError:yes; uiLoading:#loading-overlay; uiScanning:#scanning-overlay`}
                embedded
                color-space="sRGB"
                renderer="colorManagement: true, physicallyCorrectLights"
                vr-mode-ui="enabled: false"
                device-orientation-permission-ui="enabled: false"
            >
                <a-assets timeout="2500">
                    {project.image_targets.map((imageTarget, index) => (
                        <video
                            key={`target-${index}`}
                            id={`target-${index}`}
                            src={imageTarget.video}
                            muted
                            loop
                            crossOrigin="anonymous"
                            preload="auto"
                            playsInline
                        ></video>
                    ))}
                </a-assets>

                <a-camera
                    position="0 0 0"
                    look-controls="enabled: false"
                ></a-camera>

                {project.image_targets.map((imageTarget, index) => (
                    <a-entity
                        key={`entity-${index}`}
                        mindar-image-target={`targetIndex: ${index}`}
                    >
                        <a-video
                            src={`#target-${index}`}
                            data-index={index}
                            width={1.03}
                            height={(
                                (imageTarget.height / imageTarget.width) *
                                1.03
                            ).toFixed(2)}
                            position="0 0 0"
                        ></a-video>
                    </a-entity>
                ))}
            </a-scene>
            <Scanning
                orientation={project.orientation ?? 'LANDSCAPE'}
                ready={ready}
                hidden={ready !== 'yes' || found}
                scanning={scanning}
                button={button}
                onScanning={startScanning}
            />
            <Overlay
                hidden={ready !== 'yes' || !found}
                button={button}
                video={video}
            />
            {(ready === 'no' || ready === 'permissions') && (
                <Splash
                    id="loading-overlay"
                    variant="scanner"
                    project={ready === 'no' ? project : undefined}
                />
            )}
            <SoundButton video={video} />
        </>
    )
}

export default AFrameViewer
