import React from 'react'
import { styled } from '@mui/material'
import { WindowContainer } from './WindowContainer'
import { LoadingContainer } from './LoadingContainer'
import { DotSpinner } from './DotSpinner'
import { PoweredBy } from './PoweredBy'

const Logo = styled(WindowContainer)(({ theme }) => ({
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '75% auto',
    color: 'white',
    [theme.breakpoints.up('md')]: {
        backgroundSize: '40% auto',
    },
}))

function Splash({ id, project, hidden, variant = 'project' }) {
    const background = project?.splash?.background
    const logo = project?.splash?.logo
    const logoSize = project?.splash?.logo_size
    const logoPosition = project?.splash?.logo_position

    const css = {}
    if (logo) {
        css['backgroundImage'] = `url("${logo}")`
    }

    if (logoSize) {
        css['backgroundSize'] = logoSize
    }

    if (logoPosition) {
        css['backgroundPosition'] = logoPosition
    }

    return (
        <LoadingContainer
            id={id}
            className={hidden ? 'hidden' : undefined}
            style={
                background
                    ? { backgroundImage: `url("${background}")` }
                    : undefined
            }
        >
            <Logo style={css}>
                {!logo && <DotSpinner variant={variant} />}
                <PoweredBy />
            </Logo>
        </LoadingContainer>
    )
}

export { Splash }
