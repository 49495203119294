import React from 'react'
import { styled } from '@mui/material'
import { keyframes } from '@mui/system'

const kframe = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const Loading = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: 'calc(50vh - 31px)',
    left: 'calc(50vw - 120px)',
    width: '240px',
    height: '62px',
    '& h2': {
        color: '#ccc',
        margin: 0,
        lineHeight: '30px',
        fontFamily: 'Montserrat',
        fontSize: '16px',
        fontWeight: 500,
        textTransform: 'uppercase',
        textAlign: 'center',
        letterSpacing: '2px',
    },
    '& span': {
        display: 'inline-block',
        verticalAlign: 'middle',
        width: '22px',
        height: '22px',
        margin: '5px 6px',
        background: '#007DB6',
        animation: `${kframe} 1s infinite alternate`,
    },
    '& span:nth-of-type(2)': {
        background: '#008FB2',
        animationDelay: '0.2s',
    },
    '& span:nth-of-type(3)': {
        background: '#009B9E',
        animationDelay: '0.4s',
    },
    '& span:nth-of-type(4)': {
        background: '#00A77D',
        animationDelay: '0.6s',
    },
    '& span:nth-of-type(5)': {
        background: '#00B247',
        animationDelay: '0.8s',
    },
    '& span:nth-of-type(6)': {
        background: '#5AB027',
        animationDelay: '1.0s',
    },
    '& span:nth-of-type(7)': {
        background: '#A0B61E',
        animationDelay: '1.2s',
    },
}))

function DotSpinner({ variant = 'project' }) {
    return (
        <Loading>
            <h2>
                {variant === 'project' ? 'loading data' : 'loading scanner'}
            </h2>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </Loading>
    )
}

export { DotSpinner }
