import React, { useEffect, useState } from 'react'
import { getProject } from '../api/Projects'
import AFrameViewer from '../aframe/AFrameViewer'
import { Splash } from './Splash'
import { Permissions } from './Permissions'

function Viewer({ match }) {
    const stage = match.params.stage || 'PROD'
    const projectId = match.params.projectId
    const [status, setStatus] = useState('loading')
    const [project, setProject] = useState()

    const delay = async () => {
        return new Promise((resolve, _) => {
            setTimeout(() => {
                resolve()
            }, 1500)
        })
    }

    useEffect(() => {
        setStatus('loading')
        getProject(projectId, stage)
            .then((project) => {
                if (project.title) {
                    document.title = project.title
                }
                setProject(project)
                setStatus('brand')

                return delay()
            })
            .then(() => {
                setStatus('loaded')
            })
            .catch((error) => {
                setStatus(error.message)
            })
    }, [projectId, stage])

    if (status === 'loading' || !project) {
        return <Splash variant="project" />
    }

    if (status === 'brand' && project) {
        return <Splash project={project} />
    }

    if (status === 'missing-permissions') {
        return <Permissions />
    }

    if (status !== 'loaded') {
        return <h6>{status}</h6>
    }

    return (
        <AFrameViewer
            project={project}
            onMissingPermissions={() => setStatus('missing-permissions')}
        />
    )
}

export default Viewer
